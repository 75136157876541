.legal-holiday {
  color: red;
}

.regular-holiday {
  color: blue;
}

.work {
  color: #111111;
  background-color: gold;
}

.lunch {
  color:#111111;
  background-color: #d5ddf6;
}

.holiday {
  color:#111111;
  background-color: lightslategray;
}

.paid-holiday {
  color:#111111;
  background-color: lightskyblue;
}

.paid-holiday-time {
  color:#111111;
  background-color: lightskyblue;
}

.paid-holiday-date {
  color:#111111;
  background-color: lightskyblue;
}

.transfer-holiday {
  color:#111111;
  background-color: lightgreen;
}

.compensatory-holiday {
  color:#111111;
  background-color: lightgreen;
}

.holiday-work {
  color:#111111;
  background-color: mediumaquamarine;
}

.refresh-holiday {
  color:#111111;
  background-color: lightcyan;
}

.new-holiday {
  color:#111111;
  background-color: lemonchiffon;
}

.special-holiday {
  color:#111111;
  background-color: plum;
}

.unpaid-leave {
  color: #111111;
  background-color: gray;
}

.late-or-early {
  color: #111111;
  background-color: lightgray;
}

.leave {
  color: #111111;
  background-color: darkgray;
}

.overtime {
  color: #111111;
  background-color: lightsalmon;  
}

.late-night {
  color: #111111;
  background-color: beige;
}

.timetable-header {
  height: 30px;
  width: 100%;
  margin-top: 1px;
  margin-bottom: 1px;
}

.timetable-footer {
  height: 30px;
  width: 100%;
}

.timetable-row {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #bfbfbf;
}

.timetable-empty-row {
  width: 100%;
}

.vis-h0,
.vis-h1,
.vis-h2,
.vis-h3,
.vis-h4,
.vis-h22,
.vis-h23 {
  background-color: beige;
}
